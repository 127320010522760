import { Injectable, inject } from '@angular/core';
import { AuthData } from '../models/auth-data-model';
import { Observable, delay, map, of, tap } from 'rxjs';
import { User } from '../models/user-model';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Song } from '../models/song-model';
import { SongCondition } from '../models/song-condition-model';

export interface AuthResponse {
  token: string;
  id: string;
  email: string;
  is_admin: boolean;
}

export interface FetcherCache {
  conditions: Array<SongCondition>;
}

export interface ListResponse<T> {
  results: Array<T>;
  count: number;
  next: string | null;
  previous: string | null;
}

const freeUrl =
  'https://freeplay-rebuild-cms-production.s3.amazonaws.com/a/full_marks_011e8bab20.mp3';
@Injectable({
  providedIn: 'root',
})
export class FetchService {
  client = inject(HttpClient);
  private cache: FetcherCache = {
    conditions: [],
  };

  constructor() {}

  getSongConditions(): Observable<Array<SongCondition>> {
    if (environment.useMOC) {
      return of([
        { id: '1', name: 'Condition 1' },
        { id: '2', name: 'Condition 2' },
        { id: '3', name: 'Condition 3' },
        { id: '4', name: 'Condition 4' },
        { id: '5', name: 'Condition 5' },
        { id: '6', name: 'Condition 6' },
        { id: '7', name: 'Condition 7' },
        { id: '8', name: 'Condition 8' },
        { id: '9', name: 'Condition 9' },
        { id: '10', name: 'Condition 10' },
      ]).pipe(delay(1200));
    } else {
      if (this.cache.conditions.length > 0) {
        return of([...this.cache.conditions]);
      }

      return this.client.get<ListResponse<SongCondition>>(`${environment.baseURL}/condition/`).pipe(
        map(response => response.results),
        tap(res => {
          this.cache.conditions = res;
        })
      );
    }
  }

  getUsers() {
    if (environment.useMOC) {
      return of([]).pipe(delay(1200));
    } else {
      return this.client.get(`${environment.baseURL}/users/`);
    }
  }

  updateUserProfile(custedData: Record<string, string>): Observable<User> {
    return this.client
      .put<Record<string, string>>(`${environment.baseURL}/users/current/`, custedData)
      .pipe(
        map(data => {
          return this.mapUpdateProfileResponseToUser(data);
        })
      );
  }

  getUserData(authData: AuthData): Observable<User> {
    if (environment.useMOC) {
      return of({
        name: AuthData.name,
        token: 'fake token',
        id: '10',
        email: 'demo@mail.com',
      }).pipe(delay(1200));
    } else {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
      });
      return this.client
        .post<AuthResponse>(`${environment.baseURL}/api-token-auth/`, JSON.stringify(authData), {
          headers,
        })
        .pipe(
          map(response => {
            return {
              name: authData.username,
              token: response.token,
              id: response.id,
              email: response.email,
              is_admin: response.is_admin,
            };
          })
        );
    }
  }

  getSongList(requstparams?: HttpParams): Observable<ListResponse<Song>> {
    if (environment.useMOC) {
      return this.client.get('../../assets/demo/demo-songs-list.json').pipe(
        map(res => {
          const tracs = (res as any).tracks as Array<any>;
          const mapped: Array<Song> = tracs.map(el => {
            const song = new Song();
            song.id = el._id;
            song.name = el.name;
            song.url = freeUrl;
            song.is_uploaded = true;
            song.conditions = [
              { id: '1', name: 'Condition 1' },
              { id: '2', name: 'Condition 2' },
              { id: '3', name: 'Condition 3' },
            ];
            song.created_at = new Date();
            return song;
          });
          return {
            results: mapped,
            count: mapped.length,
            next: null,
            previous: null,
          };
        })
      );
    } else {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
      });

      const params = requstparams
        ? requstparams
        : new HttpParams({ fromObject: { page: 1, page_size: 25 } });

      return this.client.get<ListResponse<Song>>(`${environment.baseURL}/songs/`, {
        headers,
        params,
      });
    }
  }

  getSongData(id: string): Observable<Song> {
    if (environment.useMOC) {
      return this.client.get('../../assets/demo/demo-songs-list.json').pipe(
        delay(1100),
        map(res => {
          const tracs = ((res as any).tracks as Array<any>).filter(el => {
            return el._id === id;
          });
          if (tracs.length === 0) {
            const fakeSong = new Song();
            fakeSong.name = 'no name';
            fakeSong.id = id;
            fakeSong.url = freeUrl;
            fakeSong.key = 'sdfsdgsfgsfdg';
            fakeSong.is_uploaded = true;
            fakeSong.conditions = [{ id: '1', name: 'condition 1' }];
            return fakeSong;
          }
          const mapped: Array<Song> = tracs.map(el => {
            const song = new Song();
            song.id = el._id;
            song.name = el.name;
            song.url = freeUrl;
            song.is_uploaded = false;
            song.conditions = [
              { id: '1', name: 'Condition 1' },
              { id: '2', name: 'Condition 2' },
              { id: '3', name: 'Condition 3' },
            ];
            song.created_at = new Date();
            song.is_uploaded = false;
            return song;
          });

          return mapped[0];
        })
      );
    } else {
      return this.client.get<Song>(`${environment.baseURL}/songs/${id}/`);
    }
  }

  getSongUploadURL(id: string): Observable<string> {
    if (environment.useMOC) {
      return of('someURL').pipe(delay(1100));
    } else {
      return this.client.get(`${environment.baseURL}/songs/${id}/get_upload_url`).pipe(
        map(res => {
          return (res as Song).upload_url!;
        })
      );
    }
  }

  deleteSong(id: string) {
    if (environment.useMOC) {
      return of([]).pipe(delay(1000));
    } else {
      return this.client.delete(`${environment.baseURL}/songs/${id}/`).pipe(delay(1000));
    }
  }

  createSong(data: Partial<Song>): Observable<Song> {
    const mappedData = {
      ...data,
      conditions: data.conditions?.map(el => {
        return Number(el.id);
      }),
    };

    if (environment.useMOC) {
      const fakeSong = new Song();
      Object.assign(fakeSong, data);
      fakeSong.id = new Date().toString();
      fakeSong.url = freeUrl;
      fakeSong.key = '';
      fakeSong.is_uploaded = false;
      fakeSong.upload_url = 'someURL';
      return of(fakeSong).pipe(delay(1000));
    } else {
      return this.client.post<Song>(`${environment.baseURL}/songs/`, mappedData).pipe(
        map(res => {
          return this.mapSongUpdateResponseToSong(res);
        })
      );
    }
  }

  updateSong(id: string, data: Partial<Song>) {
    const mappedData = {
      ...data,
      conditions: data.conditions?.map(el => {
        return Number(el.id);
      }),
    };

    if (environment.useMOC) {
      const fakeSong = new Song();
      Object.assign(fakeSong, data);
      fakeSong.id = id;
      fakeSong.url = freeUrl;
      fakeSong.key = 'sdfsdgsfgsfdg';
      fakeSong.is_uploaded = data?.is_uploaded ? data?.is_uploaded : false;
      fakeSong.name = data?.name ? data?.name : 'fake name';
      return of(fakeSong).pipe(delay(1000));
    } else {
      return this.client.put<Song>(`${environment.baseURL}/songs/${id}/`, mappedData).pipe(
        map(res => {
          return this.mapSongUpdateResponseToSong(res);
        })
      );
    }
  }

  getReport(params: { start_date: string; end_date: string }) {
    return this.client.post(`${environment.baseURL}/reports/csv/`, params, {
      responseType: 'arraybuffer',
    });
  }

  uploadFile(url: string, file: File) {
    if (environment.useMOC) {
      return of('').pipe(delay(1500));
    } else {
      return this.client.put(url, file);
    }
  }

  mapSongUpdateResponseToSong(data: any): Song {
    const mapped = new Song();
    Object.assign(mapped, data);
    mapped.conditions = (data.conditions as string[]).map(el => {
      return this.cache.conditions.find(sel => sel.id === el)!;
    });
    return mapped;
  }

  mapUpdateProfileResponseToUser(data: Record<string, string>): User {
    const user = new User();
    user.token = data['token'];
    user.email = data['email'];
    user.name = data['username'];
    return user;
  }
}
