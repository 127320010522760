import { SongCondition } from './song-condition-model';

export class Song {
  id: string;
  key?: string;
  name: string;
  url: string;
  upload_url?: string;
  conditions: Array<SongCondition>;
  is_uploaded?: boolean;
  created_at: Date;
}
